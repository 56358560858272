<template>
	<div class="register">
		<div class="reg-banner" style="background-image: url(/imgs/open.jpg); background-size: cover;"></div>
		<div class="section bg-gray">
			<div class="container">
				<h3 class="tc mb20 ot">Create Your Account</h3>
				<p class="tc mb40 ab">We will process in 1-3 work days, please check out your email or phone message in anytime.</p>
				<div class="former">
					<div class="suc" v-if="suc">
						<div class="tc mb20">
							<img src="/imgs/icons8-ok-48.png">
						</div>
						<div class="tc oks">
							Submit Success!
						</div>
					</div>
					<div v-else>
						<div class="row">
							<div class="row-title"><span>Account Type</span><span class="req">*</span></div>
							<div class="flex">
								<div class="act-type " :class="{'selected':form.account_type==0}" @click="setType(0)">
									<div class="flex flex-center">
										<div class="act-icon flex flex-center">
											<img src="/imgs/icons8-user-skin-type-7-48.png">
										</div>
										<div class="act-name">Individuals</div>
									</div>
								</div>
								<div class="act-type" :class="{'selected':form.account_type==1}" @click="setType(1)">
									<div class="flex flex-center">
										<div class="act-icon flex flex-center">
											<img src="/imgs/icons8-library-48.png">
										</div>
										<div class="act-name">Institutions</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row flex flex-center">
							<div class="name-item pr">
								<div class="row-title"><span>First Name</span><span class="req">*</span></div>
								<div class="aaa">
									<input type="text" class="form-input" v-model="form.first_name">
								</div>
							</div>
							<div class="name-item pl">
								<div class="row-title"><span>Last Name</span><span class="req">*</span></div>
								<div class="aaa">
									<input type="text" class="form-input" v-model="form.last_name">
								</div>
							</div>
						</div>
						<div class="row">
							<div class="row-title"><span>Email</span><span class="req">*</span></div>
							<div class="aaa">
								<input type="text" class="form-input" v-model="form.email">
							</div>
						</div>
						<div class="row">
							<div class="row-title"><span>Phone</span><span class="req">*</span></div>
							<div class="aaa">
								<input type="text" class="form-input" v-model="form.phone">
							</div>
						</div>
						<div v-if="form.account_type==1">
							<div class="row">
								<div class="row-title"><span>Company Name</span><span class="req">*</span></div>
								<div class="aaa">
									<input type="text" class="form-input" v-model="form.company_name">
								</div>
							</div>
							<div class="row">
								<div class="row-title"><span>Company Website URL</span><span class="req">*</span></div>
								<div class="aaa">
									<input type="text" class="form-input" v-model="form.company_url">
								</div> 
							</div>
							<div class="row">
								<div class="row-title"><span>Company Business</span></div>
								<div class="aaa">
									<textarea rows="6" class="msg-input" v-model="form.company_business"></textarea>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="req-err" v-if="err">{{err}}</div>
							<button class="send-btn" @click="create" :disabled="loading">
								<div class="loader loader3" v-if="loading"></div>
								<span v-else>Open Account</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import axios from 'axios'
	export default{
		name: 'Register',
		data(){
			return {
				loading: false,
				err:'',
				suc:false,
				form:{
					account_type:0,
					first_name:'',
					last_name:'',
					phone:'',
					email:'',
					company_name:'',
					company_business:'',
					company_url:''
				}
			}
		},
		created(){
			this.form.account_type = this.$route.params.type||0
		},
		methods:{
			create(){
				if (!this.form.first_name) {
					this.err = 'Please enter first name'
					return
				}
				if (!this.form.last_name) {
					this.err = 'Please enter last name'
					return
				}
				if (!this.form.email) {
					this.err = 'Please enter email'
					return
				}
				if (!this.form.phone) {
					this.err = 'Please enter phone number'
					return
				}
				if (this.form.account_type==1) {
					if (!this.form.company_name) {
						this.err = 'Please enter company name'
						return
					}
					if (!this.form.company_url) {
						this.err = 'Please enter company URL'
						return
					}
				}
				this.loading = true
				axios.post('http://bitfree.digital/api/open/account', this.form).then(res=>{
					this.loading = false
					this.suc = true
				})
			},
			setType(a){
				this.form.account_type = a
			}
		}
	}
</script>
<style lang="scss" scoped>
	.register{
		position: relative;
	}
	.reg-banner{
		width: 100%;
		height: 340px;
	}
	.former{
		width: 600px;
		margin: 0 auto;
		padding: 30px 20px;
		box-sizing: border-box;
		background-color: #fff;
		border-radius: 4px;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.06);
	}
	.row{
		margin-bottom: 40px;
	}
	.row-title{
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 600;
	}
	.act-type{
		height: 42px;
		border: solid 1px #eee;
		border-radius: 4px;
		margin-right: 20px;
		padding: 0 16px;
		cursor: pointer;
		font-weight: 600;
	}
	.act-type.selected{
		border-color: #f7bd05;
		color: #f7bd05;
	}
	.act-icon{
		height: 42px;
		margin-right: 10px;
		img{
			height: 24px;
		}
	}
	.name-item{
		width: 50%;
		box-sizing:border-box;
	}
	.pl{
		padding-left: 10px;
	}
	.pr{
		padding-right: 10px;
	}
	.form-input{
		width: 100%;
		height: 34px;
		border: solid 1px #d1d1d1;
		outline: none;
		padding-left: 8px;
		border-radius: 4px;
		box-sizing:border-box;
	}
	.req{
		color: #f00;
		display: inline-block;
		margin-left: 3px;
	}
	.ot{
		font-size: 26px;
		font-weight: 600;
	}
	.msg-input{
		width: 100%;
		resize: none;
		border: solid 1px #d1d1d1;
		outline: none;
		border-radius: 4px;
		padding-left: 8px;
		padding-top: 8px;
	}
	.send-btn{
		min-width: 180px;
		padding: 10px 16px;
		background-color: #f7bd05;
		color: #212833;
		font-size: 15px;
		font-weight: 600;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		transition: all ease-in .3s;
	}
	.ab{
		font-size: 14px;
	}
	.send-btn:hover{
		background-color: #d19f02;
	}
	.req-err{
		color: #f00;
		margin-bottom: 5px;
	}
	.suc{
		padding: 50px 0;
	}
	.oks{
		font-size: 16px;
	}
</style>